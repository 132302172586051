define('buildTemplateFormAdmin', ['jquery', 'viewData'], function ($, viewData) {


    var init = function () {        
        var $body = $('body');
        $('.body-content').addClass('admin');
		var $templateForm = $('<div>').addClass('template-management-form admin');
        var $templateFormContent = $('<div>').addClass('template-management-form-content');
        var $button = $('<div>').addClass('save-build-template').html('SAVE BUILD TEMPLATE');
        $templateFormContent.append($('<div>').html('<div class="department-label">' + viewData.DepartmentName + '</div>  Building Product Customization:  ').append($('<input>').val(viewData.Label).addClass('form-control text-box').attr('placeholder', 'Customization Name Here...')).append($button));
        $templateForm.append($templateFormContent);

        // approved bit     
        var $approvedWrapper = $('<div>').addClass('approved-wrapper');
        var $checkbox = $('<input>').attr('type', 'checkbox');
        if (viewData.Approved) {
            $checkbox.prop("checked", true);
        }
        var $approvedLabel = $('<span>').addClass('').html('Approved');
        $approvedWrapper.append($checkbox).append($approvedLabel);
        $templateFormContent.append($approvedWrapper);

		$body.prepend($templateForm);
		setTimeout(function () {
			$body.addClass('template-management');          
			setTimeout(function () {
				$(window).resize();
			}, 600);
		}, 50);
	};

	return {                
		init: init
	};
});
define('savedBuildInitializerAdmin', ['viewData', 'jquery', 'templateForm', 'templateData', 'perspectiveHotspotPreview', 'review', 'loadedTemplates'],
	function (viewData, $, templateForm, templateData, perspectiveHotspotPreview, review, loadedTemplates) {
		'use strict';



		var zoomViewSelector = '.preview-container .zoom-view';
		var perspectiveHotspotPreviewTimer = 0;
		var reviewTimer = 0;        

        var initializeBuild = function () {

			var savedBuild = viewData.SavedBuild;

			if (!savedBuild)
				return;
						
			// initialize the hotspots in the saved build
			savedBuild.Hotspots.forEach(function (hotspot) {
				initializeHotspot(hotspot);
            });

            //populate the input of the disabled checkbox
            if (viewData.SavedBuild != null && viewData.SavedBuild.DisabledHotspots != null) {
                viewData.SavedBuild.DisabledHotspots.forEach(function (disabledHotspot, index) {                  
                    $('.hotspot-view[data-id="' + disabledHotspot + '"] .department-prevent-alterations-wrapper .input-prevent-hotspot-alterations').prop('checked', true);                  
                });
            };      


		};

		var initializeHotspot = function (hotspot) {


			// get the hotspot element
			var $hotspot = $('.hotspot-view[data-id=' + hotspot.ID + ']');

			// get the template group
			var $templateGroup = $hotspot.find('.template-group[data-id=' + hotspot.TemplateGroupID + ']');

			// mark the hotspot applied
			$templateGroup.parents('.hotspot-view').addClass('applied');

			// activate the appropriate tab
			$templateGroup.parents('.menu-tab').addClass('open applied');

			// activate the appropriate template group
			$templateGroup.addClass('open').addClass('open applied');

			// get the template form container
			var $templateFormContainer = $templateGroup.find('.template-form');

			// load the templates
			templateData.getTemplate(hotspot.Template.ID).done(function (template) {

				// load the template into the library
				loadedTemplates.loadTemplate(template);

				// initialize template form
				templateForm.initializeForm($templateFormContainer, template);

				// change the template button text
				var $templateSelectButton = $templateGroup.find('.template-button-container .select-template-button');
				$templateSelectButton.html('Change ' + $templateSelectButton.attr('data-label'));

				// remove the overlay from the previews
				var $zoomView = $(zoomViewSelector);
				$zoomView.removeClass('customizing').addClass('customizing');

				// initialize the saved build values	
				applySavedBuildValuesToForm($templateFormContainer, hotspot);

				// request the previews for the hotspots in the perspective preview
                if (!viewData.SavedBuild.OneViewProject) {
                    showPerspectiveHotspotPreviews();
                }

				// initialize the review section
                refreshReviewSection();

                // if there is at least 1 hotspot applied than show the review content instead of the project help text
                if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
                    $('.menu .project-help-text').addClass('hide');
                    $('.menu .review-container').removeClass('hide');
                }
            });
		};

		var applySavedBuildValuesToForm = function ($formContainer, hotspot) {
			hotspot.Fields.forEach(function (field) {
				var $field = $formContainer.find('.field[data-id=' + field.UUID + ']');
				$field.find('.field-option-select').val(field.ActiveOptionUUID).change();
				var $fieldOption = $field.find('.field-options .field-option[data-id=' + field.ActiveOptionUUID + ']');
				if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(field.Type) != -1) {	// text field type
					$fieldOption.find('.text-format-select').val(field.SelectedOption.ActiveFormatUUID).change();
					$fieldOption.find('.Text-field .customizable-item-wrapper').addClass('active');
					$fieldOption.find('.Text-field input.field-option-text-input').val(field.Text).change();
					$fieldOption.find('.Color-field input').val(field.ColorID).change();
					$fieldOption.find('.OutlineColor-field input').val(field.OutlineColorID).change();		
                    $fieldOption.find('.ShadowColor-field input').val(field.ShadowColorID).change();
                    $fieldOption.find('.lock-font-wrapper .lock-font').prop('checked', field.AllowFontChoice);
				}
				else if (field.Type == 1) {	// image field type
					$fieldOption.find('.Image-field input').val(field.ImageID).change();
                }
                else if (field.Type == 11) {	// custom select options - radiobutton list or checkbox group
                    for (var i = 0; i < field.SelectedOptions.length; i++)
                    {
                        $fieldOption.find('.CustomSelectOptions-field input[data-sku="' + field.SelectedOptions[i].Sku + '"]').prop("checked", true).change();
                    }
                }
                else if (field.Type === 12) {// custom image         
                    $fieldOption.find('.custom-image-content-wrapper input.custom-image-id').val(field.CustomImage.CloudinaryId);
                    $fieldOption.find('.custom-image-content-wrapper input.custom-image-version').val(field.CustomImage.Version);
				}    
				else if (buildField.Type === 15) {// // salesperson/NS sku						
					// sku 1
					$fieldOption.find('.NS-Sku1-Description').html(buildField.NetSuiteSalesPersonData.Sku1Description);
					$fieldOption.find('.NS-Sku1-Description-Hidden').html(buildField.NetSuiteSalesPersonData.Sku1Description);
					$fieldOption.find('.NS-Sku1-Price').val(buildField.NetSuiteSalesPersonData.Sku1Price);
					$fieldOption.find('.NS-Sku1-Price-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1Price);
					$fieldOption.find('.NS-Sku1-Cost').val(buildField.NetSuiteSalesPersonData.Sku1Cost);
					$fieldOption.find('.NS-Sku1-Cost-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1Cost);
					$fieldOption.find('.NS-Sku1-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1);
					$fieldOption.find('.NS-Sku1').val(buildField.NetSuiteSalesPersonData.Sku1);
					$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');
						
					// sku 2
					$fieldOption.find('.netSuite-sku2-option-wrapper').removeClass('hide');
					if (buildField.NetSuiteSalesPersonData.Sku2 != null && buildField.NetSuiteSalesPersonData.Sku2 != '') {
						$fieldOption.find('#NetSuite-Sku2-Option').click();
						$fieldOption.find('.NS-Sku2-Description').html(buildField.NetSuiteSalesPersonData.Sku2Description);
						$fieldOption.find('.NS-Sku2-Description-Hidden').html(buildField.NetSuiteSalesPersonData.Sku2Description);
						$fieldOption.find('.NS-Sku2-Price').val(buildField.NetSuiteSalesPersonData.Sku2Price);
						$fieldOption.find('.NS-Sku2-Price-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2Price);
						$fieldOption.find('.NS-Sku2-Cost').val(buildField.NetSuiteSalesPersonData.Sku2Cost);
						$fieldOption.find('.NS-Sku2-Cost-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2Cost);		
						$fieldOption.find('.NS-Sku2-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2);
						$fieldOption.find('.NS-Sku2').val(buildField.NetSuiteSalesPersonData.Sku2);
						$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');
					} else {
						$fieldOption.find('.NS-Sku2-Cost').val('');				
					}
				}
            });

            // Notes field
            $formContainer.find('.field-notes textarea').text(hotspot.Notes);
          
           
		};		
					
		var showPerspectiveHotspotPreviews = function () {
			clearTimeout(perspectiveHotspotPreviewTimer);               
			perspectiveHotspotPreviewTimer = setTimeout(function () {
				perspectiveHotspotPreview.showPerspectiveHotspotPreviews();
			}, 700);
		};

		var refreshReviewSection = function () {			
			clearTimeout(reviewTimer);
			reviewTimer = setTimeout(function () {
				review.refreshReviewUI();
			}, 700);
		};

		return {
			initializeBuild: initializeBuild
		};
	});						